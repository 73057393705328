<script setup lang="ts">
import delve from 'dlv'

const { find } = useStrapi()

const { data: advisorsResponse } = await useCachedAsyncData('advisors-response', () =>
  find('employees', {
    filters: {
      advisor: {
        $eq: true,
      },
    },
  }),
)

const advisors = delve(advisorsResponse.value, 'data', '')
</script>

<template>
  <div
    v-if="advisors"
    class="advisor-block-container"
  >
    <template
      v-for="(advisor) in advisors"
      :key="advisor.id"
    >
      <NuxtImg
        v-if="advisor.attributes.picture.data.attributes.url"
        loading="lazy"
        width="64"
        height="64"
        format="webp"
        :src="getStrapiMedia(advisor.attributes.picture.data.attributes.url)"
        :alt="advisor.attributes.name"
      />
    </template>
  </div>
</template>

<style lang="scss">
.advisor-block-container {
  display: flex;
  align-items: center;

  img {
    display: block;
    margin-left: -1rem;
    border-radius: 50px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}
</style>
